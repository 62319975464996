import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import AppConfiguration from "@/core/config/app.config";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { RESET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PURGE_PREVIOUS } from "@/core/services/store/request.module";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import * as VueGoogleMaps from "vue2-google-maps";
import accounting from "accounting-js";
import ReadMore from "vue-read-more";
import VueTippy, { TippyComponent } from "vue-tippy";
import GenicSetting from "@/core/plugins/genic-setting";

Vue.use(VueTippy);

Vue.use(GenicSetting);

Vue.component("tippy", TippyComponent);

Vue.use(ReadMore);

const GoogleMapAPIKey = process.env.VUE_APP_GOOGLE_MAP_API_KEY;

Vue.prototype.$tinyMCEAPIKey = process.env.VUE_APP_TINYMCE_API_KEY;
Vue.prototype.$dirName = __dirname;

Vue.use(VueGoogleMaps, {
  load: {
    key: GoogleMapAPIKey,
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(VueLodash, { lodash: lodash });

Vue.prototype.$haveRecordAudio = !!+process.env.VUE_APP_ENABLE_AUDIO_RECORDING;
Vue.prototype.$combineLineItem = !!+process.env.VUE_APP_COMBINE_LINE_ITEM;

Vue.prototype.$entityLimit = process.env.VUE_APP_ENTITY_LIMIT;
Vue.prototype.$hasWarranty = !!+process.env.VUE_APP_ENABLE_WARRANTY;
Vue.prototype.$hasEntity = !!+process.env.VUE_APP_ENABLE_ENTITY;

Vue.prototype.$assetURL = (param) => {
  return process.env.VUE_APP_BASE_URL + param;
};

Vue.prototype.$assetAPIURL = (profile_logo) => {
  if (profile_logo && profile_logo.file && profile_logo.file.url) {
    return profile_logo.file.url;
  }
  return process.env.VUE_APP_BASE_URL + "media/default/no-image.png";
};

Vue.prototype.$assetRAWAPIURL = (param) => {
  if (param) {
    if (typeof param == "string" && param.startsWith("/")) {
      return process.env.VUE_APP_API_FILE_PATH + param.substr(1);
    }
    return process.env.VUE_APP_API_FILE_PATH + param;
  }
  return process.env.VUE_APP_BASE_URL + "media/default/no-image.png";
};

Vue.prototype.$defaultImage =
  process.env.VUE_APP_BASE_URL + "media/default/no-image.png";
Vue.prototype.$defaultProfileImage =
  process.env.VUE_APP_BASE_URL + "media/default/no-profile-image.png";

Vue.prototype.$googleApiKey = GoogleMapAPIKey;

import VueMask from "v-mask";
Vue.use(VueMask);

Vue.prototype.$apiURL = process.env.VUE_APP_API_URL;
Vue.prototype.$filePath = process.env.VUE_APP_API_FILE_PATH;

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();
AppConfiguration.init();

let AppConfig = AppConfiguration.get();
if (AppConfig) {
  accounting.settings.symbol = AppConfig.currency.symbol;
}

accounting.settings.format = "%s %v";

Vue.prototype.$accountingJS = accounting;

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiresAuth) {
    Promise.all([
      store.dispatch(PURGE_PREVIOUS),
      store.dispatch(VERIFY_AUTH),
    ]).then(next);
  } else {
    Promise.all([store.dispatch(PURGE_PREVIOUS)]).then(next);
  }
  // reset config to initial state
  store.dispatch(RESET_BREADCRUMB);
  store.dispatch(RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
